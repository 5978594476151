import { createTheme } from "@mui/material/styles";
import "../Fonts/fonts.css";

const fontFamily = "'Montserrat', Arial, sans-serif";

const theme = createTheme({
  typography: {
    h1: {
      fontSize: "96px",
      fontFamily,
    },
    h2: {
      fontSize: "60px",
      fontFamily,
    },
    h3: {
      fontSize: "48px",
      fontFamily,
    },
    h4: {
      fontSize: "34px",
      fontFamily,
    },
    h5: {
      fontSize: "24px",
      fontFamily,
    },
    h6: {
      fontSize: "20px",
      fontFamily,
    },
    subtitle1: {
      fontSize: "16px",
      fontFamily,
    },
    subtitle2: {
      fontSize: "14px",
      fontFamily,
    },
    body1: {
      fontSize: "16px",
      fontFamily,
    },
    body2: {
      fontSize: "14px",
      fontFamily,
    },
    caption: {
      fontSize: "12px",
      fontFamily,
    },
    button: {
      fontSize: "15px",
      fontFamily,
    },
  },
  palette: {
    primary: {
      main: "#57877D",
      light: "#497D72",
      dark: "#3B7367",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#2D695C",
      light: "#1F5F51",
      dark: "#1C5447",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#18483D",
      light: "#143C33",
      dark: "#12362E",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#103029",
      light: "#143C33",
      dark: "#12362E",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#497D72",
      light: "#57877D",
      dark: "#3B7367",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#2D695C",
      light: "#1F5F51",
      dark: "#1C5447",
      contrastText: "#FFFFFF",
    },
  },
});

export default theme;
